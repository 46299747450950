<template>
    <div class="device-container">
        <div class="device-table">
            <div class="device-table-header-wrapper">
                <div class="device-table-title">
                    <p class="table-name"><b>Router / Stränge</b><span class="subtitle"> - Noch nicht zugewiesen</span></p>
                </div>
                <div class="device-filters">
                    <div class="search-container">
                        <input 
                            type="text"
                            placeholder="Seriennummer"
                            class="search-input"
                            v-model="searchDevice"
                        >
                    </div>
                    <div class="search-container">
                        <input 
                            type="text"
                            placeholder="Strangname"
                            class="search-input"
                            v-model="searchFlat"
                        >
                    </div>
                </div>
            </div>
            <div class="device-table-body-wrapper">
                <div class="table-body-header">
                    <div class="table-body-device-header">
                        <div class="device-table-column" v-for="field in deviceColumns" :key="field">
                            <div class="device-column" v-if="field.text === 'Seriennummer von Router'">
                                <div class="device-column-title">{{ field.text }} ({{ filteredDevices.length }})</div>
                            </div>
                            <div class="device-column" v-else>
                                <div class="device-column-title">{{ field.text }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="table-body-flat-header">
                        <div class="device-table-column" v-for="field in flatColumns" :key="field">
                            <div class="device-column" v-if="field.text === 'Strangname'">
                                <div class="device-column-title" :class="{'device-column-flat-title': field.text === 'Strangname'}">{{ field.text }} ({{ filteredFlats.length }})</div>
                            </div>
                            <div class="device-column" v-else>
                                <div class="device-column-title">{{ field.text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-body-content">
                    <div class="table-body-wrapper">
                        <div class="table-body-device-content" v-for="device in filteredDevices" :key="device" :class="{'table-body-selected-device': selectedDevice.uid === device.uid && checkDevice === true}"> 
                            <img v-if="device.status === 1" src="/images/TRB-active-status.png" alt="work status" class="device-status">
                            <img v-else src="/images/TRB-unactive-status.png" alt="dont work status" class="device-status">
                            <span class="device-data">TRB{{ device.uid }}</span>
                            <span class="device-date">{{ formatDate(device.started_at) }}</span>
                            <input type="checkbox" :disabled="selectedDevice.uid != device.uid && checkDevice === true" class="checkbox" @click="selectDevice(device)">
                        </div>
                    </div>
                    <div class="table-body-flat-wrapper">
                        <div class="table-body-flat-content" v-for="flat in filteredFlats" :key="flat" :class="{'table-body-selected-flat': selectedFlat.paul_syntax === flat.paul_syntax && checkFlat === true}"> 
                            <span class="flat-data">{{ flat.paul_syntax }}</span>
                            <input type="checkbox" :disabled="selectedFlat.paul_syntax != flat.paul_syntax && checkFlat === true" class="checkbox flat-checkbox" @click="selectFlat(flat)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import validationTimeZone from "../mixins/validationTimeZone.mixin";
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'Router Table',
  props: ['disableDevice', 'disableFlat'],
  mixins: [validationTimeZone],
  data() {
    return {
        deviceColumns: [
            { text: 'Status'},
            { text: 'Seriennummer von Router'},
            { text: 'Datum & Uhrzeit'},
            { text: ''}
        ],
        flatColumns: [
            { text: 'Strangname'},
            { text: ''}
        ],
        selectedDevice: {},
        selectedFlat: {},
        checkFlat: false,
        checkDevice: false,
        searchDevice: '',
        searchFlat: '',
    }
  },
  methods: {
    selectDevice(device) {
        this.selectedDevice = device;
        this.checkDevice = !this.checkDevice;

        if (this.checkDevice === false) {
            device = {}
        }
        this.$emit('device', device);
    },
    selectFlat(flat) {
        this.selectedFlat = flat;
        this.checkFlat = !this.checkFlat;
        
        if (this.checkFlat === false) {
            flat = {}
        }
        this.$emit('flat', flat);
    },
    formatDate(date) {
        const timeZone = this.validationTimeZone(date);
        return moment(timeZone).format('DD.MM.YYYY HH:mm');
    }
  },
  computed: {
    ...mapGetters(['getUnlinkedDevices', 'getFlats']),
    filteredFlats: function() {
      let flat = this.searchFlat;
      return this.getFlats.filter(item => {
        if(flat === '') {
          return true;
        } else {
          return item.paul_syntax.indexOf(flat.toUpperCase()) > -1;
        }
      })
    },
    filteredDevices: function() {
      let device = this.searchDevice;
      return this.getUnlinkedDevices.filter(item => {
        if(device === '') {
          return true;
        } else {
          return String(item.uid).indexOf(device) > -1;
        }
      })
    }
  },
  watch: {
    disableDevice(val) {
        if( val ) {
            for(let i = 0; i < this.getUnlinkedDevices.length; i++) {
                if(this.getUnlinkedDevices[i]._id === val.device._id) {
                    document.getElementsByClassName("checkbox")[i].click();
                }
            }
            this.selectedDevice = {}
            this.checkDevice = false;
        }
    },
    disableFlat(val) {
        if( val ) {
            for(let i = 0; i < this.getFlats.length; i++) {
                if(this.getFlats[i]._id === val.flat._id) {
                    document.getElementsByClassName("flat-checkbox")[i].click();
                }
            }
            this.selectedFlat = {}
            this.checkFlat = false;
        }
    }
  }
}
</script>
<style lang="scss">
.device-container {
    width: 100%;
    height: 100%;
    background: #EFF9FF;
    border-radius: 10px;

    .device-table {
        height: calc(100% - 36px);
        padding: 15px 20px 20px 20px;

        .device-table-header-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 35px;

            .device-table-title {
                padding-bottom: 5px;
                border-bottom: 1px solid rgba(2, 34, 89, 0.3);

                .table-name {
                    font-size: 18px;
                    color: #022259;

                    .subtitle {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }

            .device-filters {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 330px;
                height: 35px;

                .search-container {
                    width: 156px;
                    height: 29px;
                    position: relative;

                    input::-webkit-input-placeholder { color: rgba(2, 34, 89, 0.4); }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 5px;
                        left: 10px;
                        bottom: 0;
                        margin: auto 0;
                        width: 16px;
                        height: 16px;
                        background: url('/images/search.png') no-repeat 50% 50%;
                        background-size: cover;
                    }

                    .search-input {
                        width: 120px;
                        height: 100%;
                        color: #022259;
                        padding-left: 30px;
                        border: 2px solid #022259;
                        border-radius: 10px;
                        background: #FFFFFF;
                    }
                }
            }
        }

        .device-table-body-wrapper {
            margin-top: 16px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 10px;
            height: calc(100% - 51px);

            .table-body-header {
                display: grid;
                grid-template-columns: 69% 29.6%;
                grid-column-gap: 10px;
                padding: 15px 0 15px 0;
                width: 100%;

                .table-body-device-header {
                    display: grid;
                    grid-template-columns: 1fr 4fr 2fr 1fr; 
                    padding-left: 8px;
                    padding-right: 8px;
                    align-items: center;
                }

                .table-body-flat-header {
                    display: grid;
                    grid-template-columns: 3.2fr 1fr;
                    padding-right: 8px;
                    align-items: center;
                }

                .device-table-column {

                    .device-column {

                        .device-column-title {
                            font-size: 14px;
                            font-weight: 500;
                            color: #022259;
                            text-align: center;
                        }

                        .device-column-flat-title {
                            
                        }
                    }
                }
            }

            .table-body-content {
                display: grid;
                grid-template-columns: 69% 29.6%;
                grid-column-gap: 10px;
                width: 100%;
                height: calc(100% - 44px);

                .table-body-wrapper {
                    overflow-x: auto;
                    height: 100%;
                }

                .table-body-device-content {
                    color: #022259;
                    display: grid;
                    grid-template-columns: 1fr 4fr 2fr 1fr; 
                    padding-left: 8px;
                    padding-right: 8px;
                    align-items: center;
                    height: 50px;
                    &:nth-child(odd) {
                        background: #EFF9FF;
                    }

                    span {
                        font-size: 14px;
                    }

                    .device-status {
                        width: 15px;
                        height: 15px;
                        margin: 0 auto;
                    }

                    .device-data {
                        text-align: center;
                        
                    }

                    .device-date {
                        text-align: center;
                    }

                    .device-time {
                        text-align: center;
                        padding-right: 7px;
                    }

                    .checkbox {
                        width: 20px;
                        height: 20px;
                        margin: 0 auto;
                        margin-bottom: 3px;
                    }
                }

                .table-body-selected-device {
                    background: #D3EFFF !important;
                }

                .table-body-flat-wrapper {
                    overflow-x: auto;
                    height: 100%;

                    .table-body-flat-content {
                        display: grid;
                        grid-template-columns: 3.2fr 1fr;
                        align-items: center;
                        height: 50px;
                        &:nth-child(odd) {
                            background: #EFF9FF;
                        }

                        .flat-data {
                            text-align: center;
                            color: #022259;
                        }

                        span {
                            font-size: 14px;
                        }

                        .checkbox {
                            width: 20px;
                            height: 20px;
                            float: right;
                            margin-bottom: 3px;
                        }
                    }

                    .table-body-selected-flat {
                        background: #D3EFFF !important;
                    }
                }                
            }
        }
    }
}
::-webkit-scrollbar {
  background-color: #E5E5E5;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  margin-right: 5px;
  background-color: #022259;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #003797;
}

@media screen and (max-width: 1300px) {
    .table-body-content, .table-body-header {
        grid-template-columns: 69.1% 29.3% !important;
    }
    .table-body-flat-content, .table-body-flat-header {
        grid-template-columns: 4fr 1fr !important;
    }

    .device-table-title {
        width: 185px;
    }
}

@media screen and (max-width: 1260px) {
    .device-table-title {
        width: auto;
    }

    .table-body-content, .table-body-header {
        grid-template-columns: 69.1% 30% !important;
    }

    .table-body-wrapper, .table-body-flat-wrapper {
        height: 100% !important;
    }
}

@media screen and (max-width: 1258px) {
    .table-body-content {
        .table-body-wrapper {
            height: 93%;
        }
    }

    .table-body-content .table-body-flat-wrapper {
        height: 93%;
    }
}

@media screen and (min-width: 1920px) {
    .table-name {
        b {
            font-size: 21px;
        }

        .subtitle {
            font-size: 18px !important;
        }
    }

    .table-body-content {
        grid-template-columns: 69% 30% !important;
    }

    .device-table-header-wrapper {
        height: 45px !important;
        align-items: flex-end !important;
    }

    .device-table-title {
        padding-bottom: 10px !important;
    }

    .device-table {
        padding: 16px 29px 29px 29px !important;
        height: calc(100% - 45px) !important;
    }

    .device-filters {
        width: 470px !important;
        font-size: 18px;
        height: 45px !important;   

        .search-container {
            width: 230px !important;
            height: 39px !important;

            input::-webkit-input-placeholder {
                font-size: 18px !important;
            }

            &::before {
                left: 15px !important;
            }

            .search-input {
                width: 181px !important;
                padding-left: 43px!important;
                border-radius: 13px !important;
            }
        }
    }

    .table-body-content {
        height: calc(100% - 48px) !important;
    }

    .table-body-header {
        grid-template-columns: 69% 30% !important;
    }

    .device-table-body-wrapper {
        margin-top: 20px !important;
        border-radius: 10px !important;
        height: calc(100% - 65px) !important;


        .device-column-title {
            font-size: 18px !important;
        }

        .table-body-flat-content, .table-body-flat-header {
            grid-template-columns: 3.5fr 1fr !important;
        }

        .table-body-device-content, .table-body-flat-content {
            height: 60px !important;
            font-size: 18px !important;

            span {
                font-size: 18px !important;
            }
        }

        .device-status {
            width: 20px !important;
            height: 20px !important;
        }
    }

    .checkbox {
        width: 28px !important;
        height: 28px !important;
    }
}

@media screen and (min-width: 2560px) {
    .table-name {
        b {
            font-size: 36px !important;
        }

        .subtitle {
            font-size: 24px !important;
        }
    }

    .table-body-content {
        grid-template-columns: 69% 30.3% !important;
    }

    .device-table-header-wrapper {
        height: 60px !important;
        align-items: flex-end !important;
    }

    .table-body-header {
        height: 30px !important;
    }

    .table-body-header, .table-body-content {
        grid-column-gap: 20px !important;
        grid-template-columns: 69% 29.4% !important;
    }

    .device-table {
        padding: 26px 39px 39px 39px !important;
        height: calc(100% - 68px) !important;
    }

    .device-filters {
        width: 609px !important;
        font-size: 24px !important;
        height: 60px !important;   

        .search-container {
            width: 290px !important;
            height: 54px !important;

            input::-webkit-input-placeholder {
                font-size: 24px !important;
            }

            &::before {
                left: 20px !important;
                width: 24px !important;
                height: 24px !important;
            }

            .search-input {
                width: 231px !important;
                padding-left: 53px!important;
                border-radius: 13px !important;
            }
        }
    }

    .device-table-body-wrapper {
        margin-top: 30px !important;
        border-radius: 10px !important;
        height: calc(100% - 85px) !important;

        .device-column-title {
            font-size: 24px !important;
        }

        .table-body-device-content, .table-body-flat-content {
            height: 80px !important;
            font-size: 24px !important;

            span {
                font-size: 24px !important;
            }
        }

        .device-status {
            width: 30px !important;
            height: 30px !important;
        }
    }

    .table-body-wrapper, .table-body-flat-wrapper {
        height: calc(100% - 10px) !important;
    }

    .checkbox {
        width: 40px !important;
        height: 40px !important;
    }
}

@media screen and (min-width: 3840px) {
    .table-name {
        b {
            font-size: 48px !important;
        }

        .subtitle {
            font-size: 36px !important;
        }
    }

    .table-body-content {
        grid-template-columns: 69% 29.4% !important;
    }

    .table-body-header, .table-body-content {
        grid-column-gap: 30px !important;
    }

    .device-table-header-wrapper {
        height: 70px !important;
        align-items: flex-end !important;
    }

    .table-body-header {
        height: 70px !important;
    }

    .device-table {
        padding: 54px 65px 65px 65px !important;
        height: calc(100% - 130px) !important;
    }

    .device-filters {
        width: 822px !important;
        font-size: 36px !important;
        height: 70px !important;   

        .search-container {
            width: 404px !important;
            height: 64px !important;

            input::-webkit-input-placeholder {
                font-size: 36px !important;
            }

            &::before {
                left: 29px !important;
                width: 34px !important;
                height: 34px !important;
            }

            .search-input {
                width: 321px !important;
                padding-left: 77px!important;
                border-radius: 13px !important;
            }
        }
    }

    .device-table-body-wrapper {
        margin-top: 40px !important;
        border-radius: 10px !important;
        height: calc(100% - 95px) !important;

        .device-column-title {
            font-size: 36px !important;
        }

        .table-body-flat-content, .table-body-flat-header {
            grid-template-columns: 3.8fr 1fr !important;
        }

        .table-body-device-content, .table-body-flat-content {
            height: 100px !important;
            font-size: 36px;

            span {
                font-size: 36px !important;
            }
        }

        .device-status {
            width: 40px !important;
            height: 40px !important;
        }
    }

    .table-body-wrapper, .table-body-flat-wrapper {
        height: calc(100% - 52px) !important;
    }

    .checkbox {
        width: 50px !important;
        height: 50px !important;
    }
}
</style>