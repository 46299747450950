<template>
    <div class="connection-table-wrapper">
        <div class="connection-table">
            <div class="connection-table-header-wrapper">
                <div class="connection-table-title">
                    <p class="table-name"><b>Router / Strang</b> - <span class="subtitle">Zuordnung</span><span v-if="selectedFlat.paul_syntax"> - Wohnung {{ selectedFlat.flat_number }}</span><span v-if="selectedFlat.location"> - {{ selectedFlat.location }}</span></p>
                </div>
            </div>
            <div class="content-wrapper">
                <div class="content-wrapper-main">
                    <div class="content-main-column">
                        <div class="content-header-row">
                            <div class="content-table-column" v-for="field in tableColumns" :key="field">
                                <div class="content-column">
                                    <div class="content-column-title">{{ field.text }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="content-main-column-field-wrapper">
                            <div class="content-main-column-field" :class="{'validation-field': Object.keys(selectedDevice).length == 0 && connection === true}">
                                <span class="left-title"><span v-if="selectedDevice.uid">TRB</span>{{ selectedDevice.uid }}</span>
                                <span class="right-title" v-if="selectedDevice.started_at">{{ formatDate(selectedDevice.started_at) }}</span>
                            </div>
                            <img src="/images/cancel2.png" alt="cancel" v-if="Object.keys(selectedDevice).length !== 0" @click="cleanDeviceField">
                        </div>
                    </div>
                    <div class="content-main-column">
                        <div class="content-header-row">
                            <div class="content-table-column">
                                <div class="content-column">
                                    <div class="content-column-title">Strangname</div>
                                </div>
                            </div>
                        </div>
                        <div class="content-main-column-field-wrapper">
                            <div class="content-main-column-field flat-field" :class="{'validation-field': Object.keys(selectedFlat).length == 0 && connection === true}">
                                <span>{{ selectedFlat.paul_syntax }}</span>
                            </div>
                            <img src="/images/cancel2.png" alt="cancel" v-if="Object.keys(selectedFlat).length !== 0" @click="cleanFlatField">
                        </div>
                    </div>
                </div>
            </div>
            <div class="connection-button">
                <button @click="connectDevice">Zuordnung bestätigen</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'Router/Strang Table',
  props: ['device', "flat"],
  data() {
    return {
        tableColumns: [
            { text: 'Seriennummer von Router'},
            { text: 'Datum & Uhrzeit'}
        ],
        selectedDevice: {},
        selectedFlat: {},
        connection: false
    }
  },
  computed: {
    ...mapGetters(['getCurrentProperty'])
  },
  methods: {
    ...mapActions(['linkDevice']),
    ...mapMutations(['deleteDevice', 'addLinkedFlat', 'deleteFlat']),
    cleanDeviceField() {
        this.$emit('cleanDevice', this.selectedDevice);
        this.selectedDevice = {};
    },
    cleanFlatField() {
        this.$emit('cleanFlat', this.selectedFlat);
        this.selectedFlat = {};
    },
    connectDevice() {
        this.connection = true;
        if(Object.keys(this.selectedDevice).length !== 0 && Object.keys(this.selectedFlat).length !== 0) {
            const connection = {
                device: this.selectedDevice._id,
                flat: this.selectedFlat._id,
                token: localStorage.getItem('token')
            }
            this.$socket.emit('devices/link', connection);
            this.$emit('cleanFlat', this.selectedFlat);
            this.$emit('cleanDevice', this.selectedDevice);
            this.selectedFlat.device = this.selectedDevice;
            this.$socket.emit('flats', {
                token: localStorage.getItem('token'),
                property: this.getCurrentProperty._id
            });
            this.selectedDevice = {};
            this.selectedFlat = {};
            this.connection = false;
        }
    },
    formatDate(date) {
        return moment(date).format('DD.MM.YYYY HH:mm');
    }
  },
  watch: {
    device(val, old) {
        if(val) {
            this.selectedDevice = val.device;
        }
    },
    flat(val) {
        this.selectedFlat = val.flat;
    }
  }
}
</script>
<style lang="scss" scoped>
.connection-table-wrapper {
    width: 100%;
    height: 100%;
    background: #EFF9FF;
    border-radius: 10px;

    .connection-table {
        height: calc(100% - 40px);
        padding: 20px;

        .connection-table-header-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .connection-table-title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 5px;
                border-bottom: 1px solid rgba(2, 34, 89, 0.3);

                .table-name {
                    font-size: 18px;
                    color: #022259;

                    span {
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
        }

        .content-wrapper {
            margin-top: 21px;

            .content-wrapper-main {
                width: 100%;
                display: grid;
                grid-template-columns: 67% 32%;
                align-items: center;
                grid-column-gap: 5px;

                .content-main-column {
                    width: 85%;

                    .content-header-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 9px;
                        margin-right: 19px;
                        
                        .content-table-column {
                            .content-column {
                                .content-column-title {
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #022259;
                                }
                            }
                        }
                    }

                    .content-main-column-field-wrapper {
                        position: relative;

                        .validation-field {
                            border: 2px solid red !important;
                        }

                        .content-main-column-field {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding: 3px 10px 3px 10px;
                            background: #FFFFFF;
                            border: 2px solid #022259;
                            border-radius: 10px;
                            height: 25px;
                            cursor: pointer;

                            span {
                                font-size: 14px;
                                color: #022259;
                            }
                        }

                        img { 
                            position: absolute;
                            right: -36px;
                            top: 7px;
                            margin-left: 15px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        .connection-button {
            margin-top: 19px;
            button {
                width: 200px;
                height: 35px;
                background: #022259;
                border: 2px solid #022259;
                border-radius: 18px;
                color: #FFFFFF;
                font-weight: 300;
                font-size: 14px;
                cursor: pointer;
            }

            button:hover {
                background: #FFFFFF;
                color: #022259;
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    .connection-table {
        height: calc(100% - 58px) !important;
        padding: 29px !important;
    }

    .table-name {
        font-size: 21px !important;
        margin-top: 0 !important;

        span {
            font-size: 18px !important;
        }
    }

    .content-wrapper {
        margin-top: 20px !important;
    }

    .connection-table-title {
        min-width: auto;
        padding-bottom: 10px !important;
    }

    .content-header-row {
        margin-bottom: 13px !important;
        margin-right: 21px !important;
    }

    .content-column-title {
        font-size: 18px !important;
    }

    .content-main-column-field-wrapper {
        .content-main-column-field {
            height: 35px !important;

            span {
                font-size: 18px !important;
                color: #022259;
            }
        }

        img {
            right: -35px !important;
            top: 11px !important;
            width: 25px !important;
            height: 25px !important;
        }
    }

    .connection-button {
        margin-top: 20px !important;

        button {
            width: 260px !important;
            height: 45px !important;
            border-radius: 22px !important;
            font-size: 18px !important;
        }
    }
}

@media screen and (min-width: 2560px) {
    .connection-table {
        height: calc(100% - 78px) !important;
        padding: 39px !important;
    }

    .table-name {
        font-size: 36px !important;
        margin-top: 0 !important;

        span {
            font-size: 24px !important;
        }
    }

    .content-wrapper {
        margin-top: 30px !important;
    }

    .content-header-row {
        margin-bottom: 23px !important;
        margin-right: 38px !important;
    }

    .connection-table-title {
        min-width: auto;
    }

    .content-column-title {
        font-size: 24px !important;
    }

    .content-main-column-field-wrapper {
        .content-main-column-field {
            height: 26px !important;
            padding: 15px 25px 15px 25px !important;

            span {
                font-size: 24px !important;
            }
        }

        img {
            right: -50px !important;
            top: 15px !important;
            width: 30px !important;
            height: 30px !important;
        }
    }

    .connection-button {
        margin-top: 30px !important;

        button {
            width: 345px !important;
            height: 60px !important;
            border-radius: 30px !important;
            font-size: 24px !important;
        }
    }

    .content-main-column-field {
        height: 50px !important;
    }
}

@media screen and (min-width: 3840px) {
    .connection-table {
        height: calc(100% - 130px) !important;
        padding: 65px !important;
    }

    .table-name {
        font-size: 48px !important;
        margin-top: 0 !important;

        span {
            font-size: 36px !important;
        }
    }

    .content-wrapper {
        margin-top: 40px !important;
    }

    .content-header-row {
        margin-bottom: 23px !important;
        margin-right: 45px !important;
    }

    .connection-table-title {
        min-width: auto;
    }

    .content-column-title {
        font-size: 36px !important;
    }

    .content-main-column-field-wrapper {
        .content-main-column-field {
            height: 36px !important;

            span {
                font-size: 36px !important;
            }
        }

        img {
            right: -80px !important;
            top: 15px !important;
            width: 45px !important;
            height: 45px !important;
        }
    }

    .connection-button {
        margin-top: 40px !important;

        button {
            width: 450px !important;
            height: 70px !important;
            border-radius: 40px !important;
            font-size: 36px !important;
        }
    }

    .content-main-column-field {
        height: 50px !important;
    }
} 

</style>